<template>
    <div id="page-settings">
        <app-loader v-if="is_loading" fixed-on="desktop"></app-loader>

        <div class="container">
            <div class="row">
                <div class="col-12">
                    <h1 class="heading">Settings</h1>
                </div>

                <div class="col-12 col-tab-12 tabs-views">
                    <app-tabs v-model="tab" :tabs="tabs" :dropdownOnTablet="true" />

                    <app-views-list v-model="views.value" :views="views.types" v-if="on_teams_page"></app-views-list>
                </div>
            </div>
        </div>

        <router-view
            :view-type="views.value"

            @open-sidebar="openSidebar"
            @close-sidebar="closeSidebar"

            @apply-branding="applyBranding"

            ref="body"
        ></router-view>
    </div>
</template>

<script>
import appLoader from '@/components/app-loader'
import appTabs from '@/components/app-tabs'
import appViewsList from '@/components/app-views-list'

export default {
    components: {
        appLoader,
        appTabs,
        appViewsList,
    },

    data() {
        return {
            customer: {},

            tabs: [
                {
                    id: 'members',
                    name: 'members',
                    title: 'Members',
                },
                {
                    id: 'teams',
                    name: 'teams',
                    title: 'Teams',
                },
                {
                    id: 'org-settings',
                    name: 'org-settings',
                    title: 'Org Settings',
                },
                {
                    id: 'api-access',
                    name: 'api-access',
                    title: 'API Access',
                },
                {
                    id: 'webhooks',
                    name: 'webhooks',
                    title: 'Webhooks',
                },
                {
                    id: 'service-provider-products',
                    name: 'service-provider-products',
                    title: 'Products',
                },
            ],

            views: {
                value: 'table',
                types: ['table', 'plates']
            },

            loading: {
                teams: false,
            }
        }
    },

    created() {
        this.init()
    },

    methods: {
        init() {
            this.getTeams()
        },

        getTeams() {
            this.loading.teams = true

            const stop_loading = () => { this.loading.teams = false }

            this.$store.dispatch('getTeamsAll').then(stop_loading).catch(stop_loading)
        },



        openSidebar() {
            this.$emit('open-sidebar')
        },

        closeSidebar() {
            this.uuid = null
            this.$emit('close-sidebar')
        },



        applyBranding(branding) {
            this.$emit('apply-branding', branding)
        },
    },

    computed: {
        tab: {
            get() {
                const tab = this.tabs.find(tab => this.$route.name == tab.name || this.$route.name == tab.alias)

                if (!tab) {
                    this.tab = { name: 'members', replace: true }
                }

                return tab
            },

            set(tab) {
                this.$router.push({ name: tab.name, replace: true })
            }
        },

        on_teams_page() {
            return this.$route.name == 'teams'
        },

        is_loading() {
            let loading = false

            for (const key in this.loading) {
                if (this.loading[key]) {
                    loading = true
                    break
                }
            }

            return loading
        },
    },
}
</script>

<style lang="scss">
#page-settings {
    margin-top: 48px;

    h1 {
        margin-bottom: 40px;
    }

    .tabs-views {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        margin-bottom: 34px;

        /*
            .app-tabs {
                flex-grow: 1;
            }
        */

        .app-tabs {
            margin-right: auto;
        }

        .app-views-list {
            flex-shrink: 0;
            height: 40px;
        }
    }
}

@media (max-width: $tablet-size) {
    #page-settings {
        h1 {
            margin-bottom: 48px;
        }
    }
}

@media (max-width: $mobile-size) {
    #page-settings {
        margin-top: 32px;
        
        h1 {
            margin-bottom: 32px;
        }

        .tabs-views {
            justify-content: space-between;
            margin-bottom: 16px;

            .app-tabs {
                flex-grow: 0;
            }
        }
    }
}
</style>