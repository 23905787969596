<template>
    <div class="app-views-list">
        <button class="btn btn-view" v-for="(type, key) in views" :key="key"
            :class="{
                active: type == value,
                [`btn-view-${ type }`]: type,
            }"

            @click="setViewType(type)"
        ></button>
    </div>
</template>

<script>
export default {
    props: {
        value: { required: true },
        views: { type: [Array], default: ['table', 'plates'] },
    },

    methods: {
        setViewType(type) {
            this.$emit('input', type)
            
            if (type != this.value) {
                this.$emit('change', type)
            }
        },
    },
}
</script>

<style lang="scss">
.app-views-list {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .btn-view {
        width: 24px;
        height: 24px;
        margin-right: 24px;
        background: transparent no-repeat center;
        color: var(--color-icon-action);
        filter: grayscale(1) contrast(0);

        &:last-child {
            margin: 0;
        }

        &.active {
            opacity: 1;
            filter: grayscale(0) contrast(1);
            cursor: default;
            pointer-events: none;
        }

        &:not(.active) {
            &:hover {
                opacity: .8;
            }

            &:active {
                opacity: .9;
            }
        }

        &.btn-view-table {
            @include icon-before($icon-view-table);
        }

        &.btn-view-plates {
            @include icon-before($icon-view-plates);
        }
    }
}
</style>